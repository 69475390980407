import { atom } from "recoil";

export const currentAccountAtom = atom({
  key: "currentAccount",
  default: {
	avatar: "M",
	name: "Master",
	key: "OffWithItsHead",
	url: "https://spencer.autoscalper.trade/",
	repPath: "RepMaster",
	path: "MasterAccount",
	timezone: "America/Phoenix",
	selected: true,
  },
});

export const accountsAtom = atom({
  key: "accounts",
  default: [
    {
      avatar: "M",
      name: "Master",
      key: "OffWithItsHead",
      url: "https://spencer.autoscalper.trade/",
      repPath: "RepMaster",
      path: "MasterAccount",
      timezone: "America/Phoenix",
      selected: true,
    },
    // {
    // 	avatar: "MS",
    // 	name: "Master (Sim)",
    // 	key: "OffWithItsScalp",
    // 	url: "https://kevin.autoscalper.trade/",
    // 	repPath: "RepSim",
    // 	path: "MasterSim",
    // 	timezone: "America/Phoenix",
    // 	selected: false,
    // },
    // {
    // 	avatar: "K",
    // 	name: "Kevin Live",
    // 	key: "TheFIRE",
    // 	url: "____________",
    // 	repPath: "____________",
    // 	path: "____________",
    // 	timezone: "America/Phoenix",
    // 	selected: false,
    // },
    // {
    // 	avatar: "KS",
    // 	name: "Kevin Rehearsal",
    // 	key: "THEwater",
    // 	url: "https://kevin.autoscalper.trade/",
    // 	repPath: "Rep_KD-ASA2.0/",
    // 	path: "KD-ASA2.0/",
    // 	timezone: "America/Phoenix",
    // 	selected: false,
    // },
    // {
    // 	avatar: "S",
    // 	name: "Spencer Live",
    // 	key: "LiveApp",
    // 	url: "____________",
    // 	repPath: "____________",
    // 	path: "____________",
    // 	timezone: "America/Phoenix",
    // 	selected: false,
    // },
    // {
    // 	avatar: "S1",
    // 	name: "Spencer Rehearsal",
    // 	key: "Live",
    // 	url: "https://spencer.autoscalper.trade/",
    // 	repPath: "SF-ASA2.0/",
    // 	path: "SF-ASA2.0/",
    // 	timezone: "America/Phoenix",
    // 	selected: false,
    // },
    // {
    // 	avatar: "S2",
    // 	name: "Spencer Crimson",
    // 	key: "Crimson",
    // 	url: "____________",
    // 	repPath: "____________",
    // 	path: "____________",
    // 	timezone: "America/Phoenix",
    // 	selected: false,
    // },
    // {
    // 	avatar: "S3",
    // 	name: "Spencer iFlip",
    // 	key: "iFlip",
    // 	url: "____________",
    // 	repPath: "____________",
    // 	path: "____________",
    // 	timezone: "America/Phoenix",
    // 	selected: false,
    // },
  ],
});

export const todaysTradesAtom = atom({
  key: "todaysTrades",
  default: [],
});

export const defaultConfigAtom = atom({
  key: "defaultConfig",
  default: {
    strat: "ASA2",
    setup: "Morning Setup",
    poly: false,
    selected: false,
    live: false,
    canBuy: true,
    canSell: true,
    openOnly: false,
    isLocked: false,
    isLocked: false,
    max: "38000",
    init: "1000",
    hold: "0",
    seg: "500",
    buyT: "12000",
    sellT: "20000",
    take: ".005",
    stop: ".05",
    water: ".005",
  },
});

export const accountTotalsAtom = atom({
  key: "accountTotals",
  default: {
    id: "",
    account_number: "",
    status: "",
    currency: "",
    buying_power: "",
    regt_buying_power: "",
    daytrading_buying_power: "",
    non_marginable_buying_power: "",
    cash: "",
    pending_transfer_in: "",
    portfolio_value: "",
    pattern_day_trader: false,
    trading_blocked: false,
    transfers_blocked: false,
    account_blocked: false,
    created_at: "",
    trade_suspended_by_user: false,
    multiplier: "",
    shorting_enabled: false,
    equity: "",
    last_equity: "",
    long_market_value: "",
    short_market_value: "",
    initial_margin: "",
    maintenance_margin: "",
    last_maintenance_margin: "",
    sma: "",
    daytrade_count: 0,
  },
});

export const accountStatsAtom = atom({
  key: "accountStats",
  default: {
    PositivePnL: 0,
    NegativePnL: 0,
  },
});

export const rangeTotalsAtom = atom({
  key: "rangeTotals",
  default: [
    {
      title: "Profit/Loss",
      number: "$1,000",
    },
    {
      title: "Return",
      number: "$1,000",
    },
    {
      title: "Orders",
      number: "$1,000",
    },
    {
      title: "Avg Success",
      number: "$1,000",
    },
  ],
});

export const savedConfigsAtom = atom({
  key: "savedConfigs",
  default: [],
});
