import { Box, Typography } from "@material-ui/core";

export default function SideStat({ title, number, header, top }) {
	return (
		<Box width='100%'>
			<Box display='flex' justifyContent='space-between' width='100%'>
				<Box mr={1}>
					{!header ? (
						<Typography variant='h6' className='sideNumbers'>
							{title}
						</Typography>
					) : (
						<Box mt={top}>
							<Typography variant='h4' className='sideNumbersHeader'>
								{title}
							</Typography>
						</Box>
					)}
				</Box>
				{!header ? (
					<Typography variant='h6' className='sideNumbers'>
						{number}
					</Typography>
				) : (
					""
				)}
			</Box>
		</Box>
	);
}
