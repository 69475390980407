import { Box, TableCell } from "@material-ui/core";
import { ArrowDropDown, ArrowDropUp } from "@material-ui/icons";

export default function TableHeaders(props) {
	return (
		<TableCell>
			<a name={props.name} onClick={props.action}>
				<Box display='flex' alignItems='center'>
					{props.name}
					{props.sortCol === props.name ? (
						props.desc ? (
							<ArrowDropDown />
						) : (
							<ArrowDropUp />
						)
					) : (
						""
					)}
				</Box>
			</a>
		</TableCell>
	);
}
