import {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {
	Box,
	FormControl,
	MenuItem,
	TextField,
	Typography,
	InputLabel,
	TableCell,
	TableContainer,
	Table,
	TableBody,
	TableRow,
	TableHead,
	Collapse,
	ButtonGroup,
	Button,
	IconButton
} from '@material-ui/core'
import Select from '@material-ui/core/Select'
import {MobileDateTimePicker} from '@material-ui/pickers'
import {useRecoilState, useRecoilValue} from 'recoil'
import {
	currentAccountAtom,
	accountTotalsAtom,
	accountStatsAtom,
	rangeTotalsAtom
} from '../../atoms'

import SideStat from './SideStat'

import {ArrowDropDown, ArrowDropUp, Settings} from '@material-ui/icons'
import {
	getAggRange,
	getAggSymbols,
	getAccount,
	getTodayOrders,
	getAllOrders,
	toUSD,
	roundTenth,
	roundTenthPercent,
	toNum,
	sortMe
} from '../../../actions/actions'
import TableHeaders from './TableHeaders'
import axios from 'axios'

// Styling
const useStyles = makeStyles((theme) => ({
	container: {
		boxShadow: '0px 15px 40px rgba(0,0,0,0.2)',
		borderRadius: 10
		// maxHeight: 1000,
	},
	sortToggle: {
		cursor: 'pointer'
	},
	floatLeft: {
		float: 'left'
	},
	inline: {
		display: 'inline',
		width: '300px'
	},
	db: {
		border: '1px solid red !important'
	},
	index1: {
		zIndex: theme.zIndex.mobileStepper + 1
	},
	index2: {
		zIndex: theme.zIndex.mobileStepper + 5
	},
	index3: {
		zIndex: theme.zIndex.mobileStepper + 10
	},
	active: {
		backgroundColor: 'black',
		color: 'white',
		'&:hover': {
			backgroundColor: 'black',
			color: 'white'
		}
	}
}))

export default function Dashboard() {
	// Styling
	const classes = useStyles()

	// All States
	const [accountTotals, setAccountTotals] = useRecoilState(accountTotalsAtom)
	const [accountStats, setAccountStats] = useRecoilState(accountStatsAtom)
	const [rangeTotals, setRangeTotals] = useRecoilState(rangeTotalsAtom)
	const currentAccount = useRecoilValue(currentAccountAtom)
	const [selectedStartDate, handleStartDateChange] = useState(
		new Date('Jan 1, 2021')
	)
	const [orderCounter, setOrderCounter] = useState({
		all: 0,
		allPercent: 0,
		today: 0,
		todayPercent: 0
	})
	const [selectedEndDate, handleEndDateChange] = useState(new Date())
	const [currentData, setCurrentData] = useState([])
	const [timeframe, setTimeframe] = useState('Today')
	const [sortCol, setSortCol] = useState({
		col: 'Return',
		desc: true
	})
	const [tableDataType, setTableDataType] = useState('All')
	const sideStats = [
		{
			title: 'Main',
			number: '',
			header: true
		},
		{
			title: 'Cash',
			number: toUSD(accountTotals.cash)
		},
		{
			title: 'Last Equity',
			number: toUSD(accountTotals.last_equity)
		},
		{
			title: 'Market Value',
			number: toUSD(accountTotals.long_market_value)
		},
		{
			title: 'Buying Power',
			number: '',
			header: true,
			top: 2
		},
		{
			title: 'Main',
			number: toUSD(accountTotals.buying_power)
		},
		{
			title: 'Reg Trading',
			number: toUSD(accountTotals.regt_buying_power)
		},
		{
			title: 'Day Trading',
			number: toUSD(accountTotals.daytrading_buying_power)
		},
		{
			title: 'Non-Marginal',
			number: toUSD(accountTotals.non_marginable_buying_power)
		},
		{
			title: 'Margin',
			number: '',
			header: true,
			top: 2
		},
		{
			title: 'Initial',
			number: toUSD(accountTotals.initial_margin)
		},
		{
			title: 'Maintenance',
			number: toUSD(accountTotals.maintenance_margin)
		},
		{
			title: 'Last Maintenance',
			number: toUSD(accountTotals.last_maintenance_margin)
		},
		{
			title: 'Other Stats',
			number: '',
			header: true,
			top: 2
		},
		{
			title: 'Positive PnL',
			number: toUSD(accountStats.PositivePnL)
		},
		{
			title: 'Negative PnL',
			number: toUSD(accountStats.NegativePnL)
		},
	]

	const tableHeaders = {
		All: [
			'Ticker',
			'Return',
			'Orders',
			'Success',
			'Ask',
			'Real',
			'BuyDiff',
			'SellDiff',
			'Config'
		],
		Positions: [
			'Ticker',
			'Qty',
			'Avg',
			'Price',
			'Diff',
			'PnL',
			'Value',
			'Return',
			'Action'
		],
		Orders: ['Ticker', 'Qty', 'Price', 'Value', 'Action']
	}

	// Table Data Type Handler
	const changeDataType = (e) => {
		if (tableDataType !== e.currentTarget.name) {
			setTableDataType(e.currentTarget.name)
		}
	}

	// Timeframe Handler
	const changeTimeframe = (e) => {
		setTimeframe(e.target.value)
	}

	// Order Data Interval
	useEffect(() => {
		// Order Counter Handler
		var orders = {}
		getTodayOrders(currentAccount).then((d) => {
			orders.today = d.today
			orders.todayPercent = d.todayPercent
			getAllOrders(currentAccount).then((d) => {
				orders.all = d.all
				orders.allPercent = d.allPercent
				setOrderCounter(orders)
			})
		})
		const initInterval = setInterval(() => {
			// Order Counter Handler
			var orders = {}
			getTodayOrders(currentAccount).then((d) => {
				orders.today = d.today
				orders.todayPercent = d.todayPercent
				getAllOrders(currentAccount).then((d) => {
					orders.all = d.all
					orders.allPercent = d.allPercent
					setOrderCounter(orders)
				})
			})
		}, 10000)
		return () => clearInterval(initInterval)
	}, [])

	// Ticker Sort On Data Type Change
	useEffect(() => {
		setSortCol({
			col: 'Ticker',
			desc: true
		})
	}, [tableDataType])

	// Fast Sort
	useEffect(() => {
		var currentDataTemp = sortMe(currentData, sortCol)
		setCurrentData(currentDataTemp)
	}, [sortCol])

	// All Data Handler
	useEffect(() => {
		const ourRequest = axios.CancelToken.source() // <-- 1st step

		// Rebuild Callback >
		getAggSymbols(
			currentAccount,
			timeframe,
			selectedStartDate,
			selectedEndDate,
			sortCol,
			tableDataType,
			ourRequest,
			setAccountStats
		).then((d) => {
			setCurrentData(d)
		})

		getAccount(currentAccount).then((d) => {
			setAccountTotals(d)
		})

		getAggRange(
			currentAccount,
			timeframe,
			selectedStartDate,
			selectedEndDate
		).then((d) => {
			var rangeTotalsTemp = []
			var objTemp = {}
			objTemp.title = 'Return'
			objTemp.number = toUSD(
				roundTenth(d.SellActualAmount - d.BuyActualAmount)
			)
			rangeTotalsTemp.push(objTemp)

			objTemp = {}
			objTemp.title = 'Orders'
			objTemp.number = `${toNum(d.AllBuyOrderCount)} (${roundTenthPercent(
				d.FilledBuyOrderCount / d.AllBuyOrderCount
			)}%)`
			rangeTotalsTemp.push(objTemp)

			objTemp = {}
			objTemp.title = 'Avg Success'
			objTemp.number =
				roundTenthPercent(d.FilledBuyOrderCount / d.AllBuyOrderCount) + '%'
			rangeTotalsTemp.push(objTemp)

			setRangeTotals(rangeTotalsTemp)
		})
		let initInterval
		if (tableDataType === 'All') {
			initInterval = setInterval(() => {
				// Rebuild Callback >
				getAggSymbols(
					currentAccount,
					timeframe,
					selectedStartDate,
					selectedEndDate,
					sortCol,
					tableDataType,
					ourRequest,
					setAccountStats
				).then((d) => {
					setCurrentData(d)
				})
				getAccount(currentAccount).then((d) => {
					setAccountTotals(d)
				})
			}, 10000)
		} else {
			initInterval = setInterval(() => {
				// Rebuild Callback >
				getAggSymbols(
					currentAccount,
					timeframe,
					selectedStartDate,
					selectedEndDate,
					sortCol,
					tableDataType,
					ourRequest,
					setAccountStats
				).then((d) => {
					setCurrentData(d)
				})
				getAccount(currentAccount).then((d) => {
					setAccountTotals(d)
				})
			}, 3000)
		}
		return () => {
			clearInterval(initInterval)
			ourRequest.cancel()
		}
	}, [
		currentAccount,
		selectedStartDate,
		selectedEndDate,
		timeframe,
		sortCol,
		tableDataType
	])

	// Sort Data Handler
	function sortHandle(e) {
		let clicked = e.currentTarget.name
		let prevSort = sortCol.desc
		if (clicked === sortCol.col) {
			setSortCol({
				...sortCol,
				desc: !prevSort
			})
		} else {
			setSortCol({
				col: clicked,
				desc: true
			})
		}
	}
	return (
		<Box
			display='flex'
			flexDirection='column'
			maxWidth={1200}
			className='mainContainer'>
			<Box // Dashboard + Timeframe
				display='flex'
				flexWrap='wrap'
				justifyContent='space-between'
				alignItems='center'
				mb={3}>
				<Box>
					<Typography variant='h1'>Dashboard</Typography>
				</Box>
				<Box
					display='flex'
					justifyContent='flex-end'
					alignItems='center'
					flexWrap='wrap'>
					{timeframe !== 'Custom' ? (
						''
					) : (
						<Box order={{xs: 3, sm: 1}}>
							<Box
								display='flex'
								justifyContent='flex-end'
								alignItems='center'
								my={1}>
								<MobileDateTimePicker
									disableFuture
									renderInput={(props) => (
										<TextField variant='outlined' {...props} />
									)}
									value={selectedStartDate}
									onChange={(date) => handleStartDateChange(date)}
								/>
								<Box mx={1}>
									<Typography>–</Typography>
								</Box>
								<MobileDateTimePicker
									disableFuture
									renderInput={(props) => (
										<TextField variant='outlined' {...props} />
									)}
									value={selectedEndDate}
									onChange={(date) => handleEndDateChange(date)}
								/>
							</Box>
						</Box>
					)}
					<Box px={2} order={2} display={{xs: 'none', sm: 'block'}} />
					<Box width={150} flexGrow={1} order={{xs: 1, sm: 3}} my={2}>
						<FormControl variant='outlined' fullWidth>
							<InputLabel>Timeframe</InputLabel>
							<Select
								onChange={changeTimeframe}
								value={timeframe}
								label='Timeframe'>
								<MenuItem value={'All'}>All</MenuItem>
								<MenuItem value={'Today'}>Today</MenuItem>
								<MenuItem value={'Yesterday'}>Yesterday</MenuItem>
								<MenuItem value={'Week'}>Week</MenuItem>
								<MenuItem value={'Last 7'}>Last 7</MenuItem>
								<MenuItem value={'Month'}>Month</MenuItem>
								<MenuItem value={'Last 28'}>Last 28</MenuItem>
								<MenuItem value={'YTD'}>YTD</MenuItem>
								<MenuItem value={'Last 365'}>Last 365</MenuItem>
								<MenuItem value={'Custom'}>Custom</MenuItem>
							</Select>
						</FormControl>
					</Box>
				</Box>
			</Box>
			<Box // Everything Else
				display='flex'
				justifyContent='space-between'
				flexWrap='wrap'
				className='fG30'>
				<Box // Account Stats
					display='flex'
					flexDirection='column'
					width={320}>
					<Box // Rainbow Mainstats
						display='flex'
						flexDirection='column'
						alignItems='center'
						className={classes.index1 + ' rainbow-bg shadow fG5'}
						px={3}
						py={2}
						sx={{borderRadius: 10}}>
						<Typography variant='h2'>
							{toUSD(accountTotals.equity)}
						</Typography>
						<Box // All Time Stats
							mt={1}
							width='100%'>
							<Typography variant='h4' className='sideNumbersHeader'>
								All
							</Typography>
						</Box>
						<Box
							display='flex'
							width='100%'
							justifyContent='space-between'>
							<Typography className='sideNumbers'>Return</Typography>
							<Box display='flex'>
								<Box mr={1}>
									<Typography className='sideNumbers'>
										{toUSD(accountTotals.account_all_change)}
									</Typography>
								</Box>
								<Typography className='sideNumbers'>
									(
									{roundTenthPercent(
										accountTotals.account_all_change_percent
									)}
									%)
								</Typography>
							</Box>
						</Box>
						<Box
							display='flex'
							width='100%'
							justifyContent='space-between'>
							<Typography className='sideNumbers'>Orders</Typography>
							<Typography className='sideNumbers'>
								{`${toNum(orderCounter.all)} (${
									orderCounter.allPercent
								}%)`}
							</Typography>
						</Box>
						<Box // Today Stats
							mt={2}
							width='100%'>
							<Typography variant='h4' className='sideNumbersHeader'>
								Today
							</Typography>
						</Box>
						<Box
							display='flex'
							width='100%'
							justifyContent='space-between'>
							<Typography className='sideNumbers'>Return</Typography>
							<Box display='flex'>
								<Box mr={1}>
									<Typography className='sideNumbers'>
										{toUSD(
											accountTotals.equity -
												accountTotals.last_equity
										)}
									</Typography>
								</Box>
								<Typography className='sideNumbers'>
									(
									{Math.round(
										((accountTotals.equity -
											accountTotals.last_equity) /
											accountTotals.last_equity) *
											100 *
											100
									) / 100}
									%)
								</Typography>
							</Box>
						</Box>
						<Box
							display='flex'
							width='100%'
							justifyContent='space-between'>
							<Typography className='sideNumbers'>Orders</Typography>
							<Typography className='sideNumbers'>
								{`${toNum(orderCounter.today)} (${
									orderCounter.todayPercent
								}%)`}
							</Typography>
						</Box>
					</Box>
					<Collapse in={timeframe !== 'Today' && timeframe !== 'All'}>
						<Box // Timeframe Aggregations
							display='flex'
							flexDirection='column'
							alignItems='space-between'
							className={classes.index2 + ' shadow'}
							mt={3}
							px={3}
							py={2}
							sx={{borderRadius: 10}}>
							<Box mb={1}>
								<Typography variant='h4' className='rangeTotals'>
									Range Totals
								</Typography>
							</Box>
							<Box
								display='flex'
								flexDirection='column'
								width='100%'
								className='fG3'>
								{rangeTotals.map((obj) => (
									<SideStat
										title={obj.title}
										number={obj.number}
										header={obj.header}
										top={obj.top}
									/>
								))}
							</Box>
						</Box>
					</Collapse>
					<Box // Side Stats
						display='flex'
						flexDirection='column'
						alignItems='flex-start'
						className={classes.index3 + ' shadow fG3'}
						px={3}
						py={2}
						mt={3}
						sx={{borderRadius: 10}}>
						{sideStats.map((obj) => (
							<SideStat
								title={obj.title}
								number={obj.number}
								header={obj.header}
								top={obj.top}
							/>
						))}
					</Box>
				</Box>
				<Box // Desktop Table
					display={{xs: 'flex', md: 'flex'}}
					flexGrow={1}
					maxWidth={850}>
					<Box width='100%'>
						<Box // Table Choice
							mb={2}>
							<ButtonGroup
								color='primary'
								aria-label='outlined primary button group'>
								<Button
									onClick={changeDataType}
									name='All'
									className={
										tableDataType === 'All' ? classes.active : ''
									}>
									All
								</Button>
								<Button
									onClick={changeDataType}
									name='Positions'
									className={
										tableDataType === 'Positions'
											? classes.active
											: ''
									}>
									Positions
								</Button>
								<Button
									onClick={changeDataType}
									name='Orders'
									className={
										tableDataType === 'Orders' ? classes.active : ''
									}>
									Orders
								</Button>
							</ButtonGroup>
						</Box>
						<TableContainer // All Table
							className={classes.container}>
							<Table stickyHeader>
								<TableHead>
									<TableRow>
										{Object.keys(tableHeaders).map((k) => {
											if (k === tableDataType) {
												return tableHeaders[k].map((v) => {
													return (
														<TableHeaders
															name={v}
															sortCol={sortCol.col}
															desc={sortCol.desc}
															action={sortHandle}
														/>
													)
												})
											}
										})}
									</TableRow>
								</TableHead>
								{tableDataType === 'All' ? (
									<TableBody>
										{currentData.map((data) => (
											<TableRow key={data.Symbol} p={-1}>
												<TableCell>{data.Symbol}</TableCell>
												<TableCell>{`$${data.Return}`}</TableCell>
												<TableCell>
													{data.AllBuyOrderCount}
												</TableCell>
												<TableCell>{`${data.Success}%`}</TableCell>
												<TableCell>
													{`$${data.BalancedAskingAveragePrice}`}
												</TableCell>
												<TableCell>
													{`$${data.BalancedActualAveragePrice}`}
												</TableCell>
												<TableCell>{`$${data.BuyDiff}`}</TableCell>
												<TableCell>{`$${data.SellDiff}`}</TableCell>
												<TableCell>
													<Button variant='contained' size='small'>
														<Settings fontSize='small' />
													</Button>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								) : tableDataType === 'Positions' ? (
									<TableBody>
										{currentData.map((data) => (
											<TableRow key={data.Symbol} p={-1}>
												<TableCell>{data.Symbol}</TableCell>
												<TableCell>{data.Quantity}</TableCell>
												<TableCell>{toUSD(data.Average)}</TableCell>
												<TableCell>
													{toUSD(data.CurrentPrice)}
												</TableCell>
												<TableCell>{toUSD(data.Diff)}</TableCell>
												<TableCell>
													{roundTenthPercent(data.PnLPercent)}
												</TableCell>
												<TableCell>{data.Value}</TableCell>
												<TableCell>{toUSD(data.Return)}</TableCell>
												<TableCell>
													<Button variant='contained' size='small'>
														Close
													</Button>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								) : tableDataType === 'Orders' ? (
									<TableBody>
										{currentData.map((data) => (
											<TableRow key={data.Symbol} p={-1}>
												<TableCell>{data.Symbol}</TableCell>
												<TableCell>{data.Quantity}</TableCell>
												<TableCell>{data.Average}</TableCell>
												<TableCell>{data.Value}</TableCell>
												<TableCell>
													<Button variant='contained' size='small'>
														Cancel
													</Button>
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								) : (
									''
								)}
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

/*

//////// Tickers Today For Trading Dashboard

//////// Main Dashboard (Today Only)
Add Settings Action

//////// Config Presets / Save As New + Overide Old

*/
