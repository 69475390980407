import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core/styles'
import { grey, red, indigo} from '@material-ui/core/colors'

const theme = createTheme({
    typography: {
        fontFamily: [
            'sans-serif',
        ].join(','),
        h1: {
            fontSize: 65,
            fontWeight: 'bolder',
        },
        h2: {
            fontSize: 45,
            fontWeight: 'bolder',
        }
    },
    palette: {
        primary: {
            main: grey[900],
        },
        secondary: {
            main: red[800],
        },
        neutral: indigo[100],
    },
})

export default theme