import { Box, Button, Grid, makeStyles, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

const useStyles = makeStyles((theme) => ({
    stockChoice: {
        boxShadow: "0px 5px 10px rgba(0,0,0,.1)",
        borderRadius: "6px",
        cursor: "pointer",
        userSelect: "none",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    stockChosen: {
        boxShadow: "0px 15px 40px rgba(0,0,0,.25)",
        borderRadius: "6px",
        cursor: "pointer",
        userSelect: "none",
        paddingTop: "10px",
        paddingBottom: "10px",
        paddingLeft: "20px",
        paddingRight: "20px",
    },
    liveI: {
        position: "absolute",
        top: 2,
        right: 2,
    },
}));

export default function StockSelection(props) {
    const classes = useStyles();
    return (
        <Grid item>
            <Box position='relative'>
                {props.live === false || props.add ? (
                    ""
                ) : (
                    <Box className={classes.liveI}>
                        <FiberManualRecordIcon
                            color='secondary'
                            style={{ fontSize: 15 }}
                        />
                    </Box>
                )}
                <Button
                    id={props.id}
                    key={props.name}
                    selected={props.selected}
                    onClick={
                        props.add
                            ? props.action
                            : (e) => props.action(e.currentTarget.id)
                    }
                    className={
                        props.selected === true
                            ? classes.stockChosen
                            : classes.stockChoice
                    }
                    color='secondary'>
                    <Typography color='primary'>{props.ticker}</Typography>
                </Button>
            </Box>
        </Grid>
    );
}
