import { Box } from "@material-ui/core";
import React from "react";

export default function Stats() {
  return (
    <Box display='flex' flexDirection="column" width={1}>
      <Box display='flex' mb={3} fontSize={5}>
        <h1>Stats</h1>
      </Box>
    </Box>
  );
}
