import React, { useState, useEffect } from "react";
import {
	Box,
	FormControl,
	FormControlLabel,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	Switch,
	TextField,
	Typography,
	Button,
	makeStyles,
	ButtonGroup,
	Collapse,
	Grow,
	Divider,
} from "@material-ui/core";
import {
	SaveRounded,
	CloudDownloadRounded,
	Sync,
	ExpandMore,
	ExpandLess,
} from "@material-ui/icons";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useRecoilState, useRecoilValue } from "recoil";
import { useSnackbar } from "notistack";

import {
	todaysTradesAtom,
	currentAccountAtom,
	defaultConfigAtom,
} from "../../atoms";
import {
	buildTodaysTrades,
	getTodaysTraded,
	sellAllSymbol,
	closeOpenOrders,
	updateSymbolConfig,
	executeManualOrder,
	syncReport,
} from "../../../actions/actions";
import StockSelection from "./StockSelection";

import { set } from "date-fns";
import axios from "axios";

// Custom Styles
const useStyles = makeStyles((theme) => ({
	modal: {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		boxShadow: "0px 15px 80px rgba(0,0,0,0.1)",
		borderRadius: 10,
	},
}));

export default function Trade() {
	// State Management
	const classes = useStyles();
	const currentAccount = useRecoilValue(currentAccountAtom);
	const defaultConfig = useRecoilValue(defaultConfigAtom);
	const { enqueueSnackbar } = useSnackbar();
	const [todaysTrades, setTodaysTrades] = useRecoilState(todaysTradesAtom);
	const [addModal, setAddModal] = useState(false);
	const [todaysTradesTemp, setTodaysTradesTemp] = useState([]);
	const [currentConfig, setCurrentConfig] = useState({
		key: "Nothing",
		ticker: "Nothing",
		// strat: "ASA2",
		selected: false,
		live: false,
		setup: "Morning Setup",
		poly: false,
		max: "2000",
		init: "1000",
		hold: "400",
		seg: "20",
		refresh: "1200",
		timeout: "35000000",
		latency: "5000",
		buyT: "12000",
		sellT: "20000",
		canBuy: true,
		canSell: true,
		openOnly: false,
		isLocked: false,
		isForced: false,
		take: ".005",
		stop: ".05",
		water: ".005",
	});
	const [changedConfigs, setChangedConfigs] = useState({
		key: false,
		ticker: false,
		// strat: false,
		selected: false,
		live: false,
		setup: false,
		poly: false,
		max: false,
		init: false,
		hold: false,
		seg: false,
		refresh: false,
		timeout: false,
		latency: false,
		buyT: false,
		sellT: false,
		canBuy: false,
		canSell: false,
		openOnly: false,
		isLocked: false,
		isForced: false,
		take: false,
		stop: false,
		water: false,
	});
	const [updateReady, setUpdateReady] = useState(false);
	const [configToggle, setConfigToggle] = useState(false);
	const [limitOrder, setLimitOrder] = useState(true);
	const [isForced, setIsForced] = useState(false);
	const [orderDetails, setOrderDetails] = useState({
		type: "limit",
		tif: "day",
		qty: 30,
		limit: "",
	});
	const [manualErrors, setManualErrors] = useState({
		qty: false,
		limit: false,
	});

	function sellAllSymbolHandler() {
		sellAllSymbol(currentAccount, currentConfig.ticker).then((d) => {
			console.log(d);
		});
	}

	function closeOpenOrdersHandler() {
		closeOpenOrders(currentAccount, currentConfig.ticker).then((d) => {
			console.log(d);
		});
	}

	function updateSymbolConfigHandler() {
		updateSymbolConfig(
			currentAccount,
			currentConfig.ticker,
			currentConfig
		).then((d) => {
			console.log(d);
		});
	}

	// Atom Config
	function updateTodaysTrades(e) {
		e.preventDefault();
		var todaysTradesTempCopy = todaysTradesTemp.map((a) => ({ ...a }));
		setTodaysTrades(todaysTradesTempCopy);
		snack("Config updated for " + currentConfig.ticker, "success");
	}

	// Config Toggles
	function handleConfigToggle() {
		setConfigToggle((prevConfigToggle) => !prevConfigToggle);
	}

	// Is Forced Toggle Handler
	function isForcedToggle() {
		setIsForced((prevForcedToggle) => !prevForcedToggle);
	}

	// Temporary Config
	const changeSelectedStock = (index) => {
		var todaysTradesCopy = todaysTrades.map((a) => ({ ...a }));
		todaysTradesCopy.forEach((obj) => {
			if (obj.key === index) {
				obj.selected = true;
				setCurrentConfig(obj);
			} else {
				obj.selected = false;
			}
		});
		setTodaysTrades(todaysTradesCopy);

		var todaysTradesTempCopy = todaysTradesTemp.map((a) => ({ ...a }));
		todaysTradesTempCopy.forEach((obj) => {
			if (obj.key === index) {
				obj.selected = true;
				setCurrentConfig(obj);
			} else {
				obj.selected = false;
			}
		});
		setTodaysTradesTemp(todaysTradesTempCopy);
	};
	const changeTodaysStocksTemp = (update, value) => {
		var todaysTradesTempCopy = todaysTradesTemp.map((a) => ({ ...a }));
		todaysTradesTempCopy.forEach((obj, i) => {
			if (obj.key === currentConfig.key) {
				obj = { ...obj, [update]: value };
				todaysTradesTempCopy[i] = obj;
				setCurrentConfig(obj);
				setTodaysTradesTemp(todaysTradesTempCopy);
			}
		});
	};

	// Populate Todays Stocks
	useEffect(() => {
		// syncReport(currentAccount).then((d) => {
		getTodaysTraded(currentAccount).then((d) => {
			if (d) {
				buildTodaysTrades(currentAccount, d).then((d) => {
					setTodaysTrades(d);
					setTodaysTradesTemp(d);
					setCurrentConfig(d[0]);
				});
			} else {
				console.log("No Trades Today Yet 😢");
			}
		});
		// });
	}, [currentAccount]);

	// Config Difference Checker
	useEffect(() => {
		const diffChecker = () => {
			var changedConfigsCopy = {};
			todaysTrades.forEach((obj) => {
				if (obj.key === currentConfig.key) {
					var changeCount = 0;
					for (const [k, v] of Object.entries(currentConfig)) {
						if (obj[k] !== v && k !== "selected") {
							changedConfigsCopy[k] = true;
							changeCount++;
						} else {
							changedConfigsCopy[k] = false;
						}
					}
					changeCount > 0 ? setUpdateReady(true) : setUpdateReady(false);
					setChangedConfigs(changedConfigsCopy);
				}
			});
		};
		diffChecker();
	}, [currentConfig, todaysTrades]);

	// Snackbar Handler
	const snack = (message, type, persist) => {
		enqueueSnackbar(message, {
			variant: type,
			autoHideDuration: 3000,
			persist: persist,
			backgroundColor: "black !important",
		});
	};

	// Add Stock Handlers
	const [canAddStock, setCanAddStock] = useState(false);
	const toggleAddModal = () => {
		setAddModal((prev) => !prev);
	};
	const addStockAction = (ticker) => {
		ticker.preventDefault();
		ticker = ticker.target[0].value.toUpperCase();
		var newStock = { ...defaultConfig };
		newStock.key = ticker;
		newStock.ticker = ticker;
		newStock.strat = ticker + "_ASA3.0";
		var todaysTradesTempCopy = todaysTradesTemp.map((a) => ({ ...a }));
		todaysTradesTempCopy.push(newStock);
		setTodaysTradesTemp(todaysTradesTempCopy);
		var todaysTradesCopy = todaysTrades.map((a) => ({ ...a }));
		todaysTradesCopy.push(newStock);
		setTodaysTrades(todaysTradesCopy);
		toggleAddModal();
	};
	// const [validTicker, setValidTicker] = useState(true);
	const validateTicker = (ticker) => {
		ticker = ticker.toUpperCase();
		// var validTicker = ticker !== 'AMC' && ticker !== '' ? false : true;
		// var readyToAdd = ticker !== 'AMC' ? false : true;
		var readyToAdd = ticker === "" ? "" : true;
		// setValidTicker(validTicker)
		setCanAddStock(readyToAdd);
	};

	// Manual Trading Handler
	const setLimitPrice = (e) => {
		var copy = { ...orderDetails };
		copy.limit = e.target.value;
		setOrderDetails(copy);
	};
	function handleOrderDetails(e) {
		var key = e.target.name;
		var value = e.target.value;
		var copy = { ...orderDetails };
		copy = { ...copy, [key]: value };
		setOrderDetails(copy);
		if (key === "type") {
			value === "limit" ? setLimitOrder(true) : setLimitOrder(false);
		}
	}
	function executeManual(e) {
		var buyOrSell = e.currentTarget.name;
		if (!orderDetails.qty) {
			setManualErrors({
				qty: true,
				limit: false,
			});
			snack("Please Enter A Quantity", "error");
		} else if (limitOrder && !orderDetails.limit) {
			setManualErrors({
				limit: true,
			});
			snack("Please Enter A Limit Price", "error");
		} else {
			setManualErrors({
				qty: false,
				limit: false,
			});
			executeManualOrder(
				currentAccount,
				currentConfig,
				orderDetails,
				limitOrder,
				isForced,
				buyOrSell
			).then((d) => {
				console.log(d);
			});
		}
	}

	return (
		<Box display='flex' flexDirection='column' className='mainContainer'>
			<Box maxWidth={725}>
				<Box mb={2}>
					<Typography variant='h1'>Trade</Typography>
				</Box>
				<Box mb={4}>
					<Grid container spacing={3}>
						{todaysTrades.map((item) => (
							<StockSelection
								key={item.key}
								id={item.key}
								ticker={item.ticker}
								live={item.live}
								selected={item.selected}
								action={changeSelectedStock}
							/>
						))}
						<StockSelection
							add={true}
							key='add'
							id='add'
							ticker='+'
							selected={false}
							action={toggleAddModal}
						/>
					</Grid>
				</Box>
				<Grow in={currentConfig.selected}>
					<Box p={3} sx={{ border: "1px solid black", borderRadius: 5 }}>
						{/* Trade Stock Actions */}
						<Grow in={currentConfig.selected}>
							<Box>
								<form onSubmit={updateTodaysTrades}>
									{/* Top Stock Info */}
									<Box
										display='flex'
										alignItems='center'
										justifyContent='space-between'
										flexWrap='wrap'
										mb={3}>
										<Box
											display='flex'
											alignItems='center'
											flexWrap='wrap'
											flexGrow={1}>
											<Box mr={2}>
												<Typography variant='h4'>
													{currentConfig.ticker}
												</Typography>
											</Box>
											{/* <FormControlLabel
												control={
													<Switch
														name='live'
														checked={currentConfig.live}
														onChange={(e) =>
															changeTodaysStocksTemp(
																e.target.name,
																e.target.checked
															)
														}
													/>
												}
												label='BEHEAD'
											/> */}
										</Box>
										<Box
											display='flex'
											alignItems='center'
											justifyContent='flex-end'
											flexWrap='wrap'
											flexGrow={1}>
											{/* <Grow in={configToggle}>
												<Box mr={2}>
													<ButtonGroup>
														<Button
															endIcon={<CloudDownloadRounded />}>
															Load
														</Button>
														<Button endIcon={<SaveRounded />}>
															Save As
														</Button>
													</ButtonGroup>
												</Box>
											</Grow> */}
											<Box>
												<Button
													variant='outlined'
													onClick={handleConfigToggle}>
													{!configToggle ? (
														<ExpandMore />
													) : (
														<ExpandLess />
													)}
												</Button>
											</Box>
										</Box>
									</Box>
									{/* Config Toggle */}
									<Collapse in={configToggle}>
										<Box mb={2}>
											<Grid
												container
												alignItems='center'
												spacing={2}>
												{/* <Grid item>
													<Select
														variant='outlined'
														name='setup'
														value={currentConfig.setup}
														onChange={(e) =>
															changeTodaysStocksTemp(
																e.target.name,
																e.target.value
															)
														}>
														<MenuItem value='Morning Setup'>
															Morning Setup
														</MenuItem>
														<MenuItem value='Afternoon Setup'>
															Afternoon Setup
														</MenuItem>
														<MenuItem value='Sell All'>
															Sell All
														</MenuItem>
													</Select>
												</Grid> */}
												{/* <Grid item>
													<FormControlLabel
														control={
															<Switch
																name='poly'
																checked={currentConfig.poly}
																onChange={(e) =>
																	changeTodaysStocksTemp(
																		e.target.name,
																		e.target.checked
																	)
																}
															/>
														}
														label={
															<Typography
																color={
																	changedConfigs.poly
																		? "secondary"
																		: "primary"
																}>
																Poly
															</Typography>
														}
													/>
												</Grid> */}
												<Grid item>
													<FormControlLabel
														control={
															<Switch
																name='isLocked'
																checked={currentConfig.isLocked}
																onChange={(e) =>
																	changeTodaysStocksTemp(
																		e.target.name,
																		e.target.checked
																	)
																}
															/>
														}
														label={
															<Typography
																color={
																	changedConfigs.isLocked
																		? "secondary"
																		: "primary"
																}>
																Is Locked
															</Typography>
														}
													/>
												</Grid>
												<Grid item>
													<FormControlLabel
														control={
															<Switch
																name='canBuy'
																checked={currentConfig.canBuy}
																onChange={(e) =>
																	changeTodaysStocksTemp(
																		e.target.name,
																		e.target.checked
																	)
																}
															/>
														}
														label={
															<Typography
																color={
																	changedConfigs.canBuy
																		? "secondary"
																		: "primary"
																}>
																Can Buy
															</Typography>
														}
													/>
												</Grid>
												<Grid item>
													<FormControlLabel
														control={
															<Switch
																name='canSell'
																checked={currentConfig.canSell}
																onChange={(e) =>
																	changeTodaysStocksTemp(
																		e.target.name,
																		e.target.checked
																	)
																}
															/>
														}
														label={
															<Typography
																color={
																	changedConfigs.canSell
																		? "secondary"
																		: "primary"
																}>
																Can Sell
															</Typography>
														}
													/>
												</Grid>
											</Grid>
										</Box>
										<Box mb={2}>
											<Grid container spacing={3}>
												<Grid item xs={12} sm={3}>
													<FormControl
														fullWidth
														variant='outlined'>
														<InputLabel>
															Max Allocation
														</InputLabel>
														<OutlinedInput
															name='max'
															type='number'
															error={changedConfigs.max}
															value={currentConfig.max}
															onChange={(e) =>
																changeTodaysStocksTemp(
																	e.target.name,
																	e.target.value
																)
															}
															startAdornment={
																<InputAdornment position='start'>
																	$
																</InputAdornment>
															}
															labelWidth={105}
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} sm={3}>
													<FormControl
														fullWidth
														variant='outlined'>
														<InputLabel>
															Initial Amount
														</InputLabel>
														<OutlinedInput
															name='init'
															type='number'
															error={changedConfigs.init}
															value={currentConfig.init}
															onChange={(e) =>
																changeTodaysStocksTemp(
																	e.target.name,
																	e.target.value
																)
															}
															startAdornment={
																<InputAdornment position='start'>
																	$
																</InputAdornment>
															}
															labelWidth={145}
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} sm={3}>
													<FormControl
														fullWidth
														variant='outlined'>
														<InputLabel>Hold Amount</InputLabel>
														<OutlinedInput
															name='hold'
															type='number'
															error={changedConfigs.hold}
															value={currentConfig.hold}
															onChange={(e) =>
																changeTodaysStocksTemp(
																	e.target.name,
																	e.target.value
																)
															}
															startAdornment={
																<InputAdornment position='start'>
																	$
																</InputAdornment>
															}
															labelWidth={95}
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} sm={3}>
													<FormControl
														fullWidth
														variant='outlined'>
														<InputLabel>
															Segmented Amount
														</InputLabel>
														<OutlinedInput
															name='seg'
															type='number'
															error={changedConfigs.seg}
															value={currentConfig.seg}
															onChange={(e) =>
																changeTodaysStocksTemp(
																	e.target.name,
																	e.target.value
																)
															}
															startAdornment={
																<InputAdornment position='start'>
																	$
																</InputAdornment>
															}
															labelWidth={145}
														/>
													</FormControl>
												</Grid>
											</Grid>
										</Box>
										<Box mb={2}>
											<Grid container spacing={3}>
												<Grid item xs={12} sm={4}>
													<FormControl
														fullWidth
														variant='outlined'>
														<InputLabel>Take Percent</InputLabel>
														<OutlinedInput
															name='take'
															type='number'
															error={changedConfigs.take}
															value={currentConfig.take}
															onChange={(e) =>
																changeTodaysStocksTemp(
																	e.target.name,
																	e.target.value
																)
															}
															endAdornment={
																<InputAdornment position='end'>
																	%
																</InputAdornment>
															}
															labelWidth={110}
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} sm={4}>
													<FormControl
														fullWidth
														variant='outlined'>
														<InputLabel>Stop Percent</InputLabel>
														<OutlinedInput
															name='stop'
															type='number'
															error={changedConfigs.stop}
															value={currentConfig.stop}
															onChange={(e) =>
																changeTodaysStocksTemp(
																	e.target.name,
																	e.target.value
																)
															}
															endAdornment={
																<InputAdornment position='end'>
																	%
																</InputAdornment>
															}
															labelWidth={95}
															fullWidth
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} sm={4}>
													<FormControl
														fullWidth
														variant='outlined'>
														<InputLabel>Water Percent</InputLabel>
														<OutlinedInput
															name='water'
															type='number'
															error={changedConfigs.water}
															value={currentConfig.water}
															onChange={(e) =>
																changeTodaysStocksTemp(
																	e.target.name,
																	e.target.value
																)
															}
															endAdornment={
																<InputAdornment position='end'>
																	%
																</InputAdornment>
															}
															labelWidth={120}
															fullWidth
														/>
													</FormControl>
												</Grid>
											</Grid>
										</Box>
										<Box mb={2}>
											<Grid container spacing={3}>
												<Grid item xs={12} sm={4}>
													<FormControl
														fullWidth
														variant='outlined'>
														<InputLabel>
															Default Buy Timeout
														</InputLabel>
														<OutlinedInput
															name='buyT'
															type='number'
															error={changedConfigs.buyT}
															value={currentConfig.buyT}
															onChange={(e) =>
																changeTodaysStocksTemp(
																	e.target.name,
																	e.target.value
																)
															}
															endAdornment={
																<InputAdornment position='end'>
																	ms
																</InputAdornment>
															}
															labelWidth={148}
															fullWidth
														/>
													</FormControl>
												</Grid>
												<Grid item xs={12} sm={4}>
													<FormControl
														fullWidth
														variant='outlined'>
														<InputLabel>
															Default Sell Timeout
														</InputLabel>
														<OutlinedInput
															name='sellT'
															type='number'
															error={changedConfigs.sellT}
															value={currentConfig.sellT}
															onChange={(e) =>
																changeTodaysStocksTemp(
																	e.target.name,
																	e.target.value
																)
															}
															endAdornment={
																<InputAdornment position='end'>
																	ms
																</InputAdornment>
															}
															labelWidth={148}
															fullWidth
														/>
													</FormControl>
												</Grid>
											</Grid>
										</Box>
										<Box display='flex' mt={3} mb={3}>
											<Button
												size='large'
												variant='contained'
												color='secondary'
												disabled={!updateReady}
												onClick={updateSymbolConfigHandler}
												fullWidth
												type='submit'
												startIcon={<Sync />}>
												Update
											</Button>
										</Box>
										<Box mb={3}>
											<Divider />
										</Box>
									</Collapse>
								</form>
								{/* Manual Trading Stuff */}
								<Box>
									<Box display='flex' alignItems='center'>
										<Box mb={2}>
											<Typography variant='h4'>35.2339</Typography>
											<Typography variant='body2'>
												Current Price
											</Typography>
										</Box>
										<Box ml={3}>
											<FormControlLabel
												control={
													<Switch
														name='isForced'
														checked={isForced}
														onChange={isForcedToggle}
													/>
												}
												label={<Typography>Forced</Typography>}
											/>
										</Box>
									</Box>
									<Grid container spacing={2}>
										<Grid item xs={6} sm={3} md={3}>
											<Select
												variant='outlined'
												name='type'
												onChange={handleOrderDetails}
												value={orderDetails.type}
												fullWidth>
												<MenuItem value='market'>Market</MenuItem>
												<MenuItem value='limit'>Limit</MenuItem>
											</Select>
										</Grid>
										<Grid item xs={6} sm={3} md={3}>
											<Select
												variant='outlined'
												name='tif'
												onChange={handleOrderDetails}
												value={orderDetails.tif}
												fullWidth>
												<MenuItem value='day'>Day</MenuItem>
												<MenuItem value='gtc'>GTC</MenuItem>
											</Select>
										</Grid>
										<Grid item xs={6} sm={3} md={3}>
											<TextField
												variant='outlined'
												name='qty'
												label='Quantity'
												type='number'
												error={manualErrors.qty}
												value={orderDetails.qty}
												onChange={handleOrderDetails}
												fullWidth>
												1
											</TextField>
										</Grid>
										<Grow in={limitOrder}>
											<Grid item xs={6} sm={3} md={3}>
												<FormControl fullWidth variant='outlined'>
													<InputLabel>Limit Price</InputLabel>
													<OutlinedInput
														name='limit price'
														type='number'
														error={manualErrors.limit}
														value={orderDetails.limit}
														onChange={setLimitPrice}
														startAdornment={
															<InputAdornment position='start'>
																$
															</InputAdornment>
														}
														labelWidth={80}
													/>
												</FormControl>
											</Grid>
										</Grow>
									</Grid>
								</Box>
								<Box mt={2}>
									<Grid container spacing={2}>
										<Grid item xs={12} sm={5}>
											<ButtonGroup fullWidth>
												<Button
													variant='contained'
													size='large'
													onClick={closeOpenOrdersHandler}
													fullWidth>
													Cancel
												</Button>
												<Button
													onClick={sellAllSymbolHandler}
													variant='contained'
													size='large'
													fullWidth>
													Liquidate
												</Button>
											</ButtonGroup>
										</Grid>
										<Grid item xs={12} sm={7}>
											<ButtonGroup fullWidth>
												<Button
													name='Sell'
													onClick={executeManual}
													variant='contained'
													color='secondary'
													size='large'
													fullWidth>
													Sell
												</Button>
												<Button
													name='Buy'
													onClick={executeManual}
													variant='contained'
													color='secondary'
													size='large'
													fullWidth>
													Buy
												</Button>
											</ButtonGroup>
										</Grid>
									</Grid>
								</Box>
							</Box>
						</Grow>
					</Box>
				</Grow>
			</Box>
			{/* Add Stock Modal */}
			<Modal
				className={classes.modal}
				open={addModal}
				onClose={toggleAddModal}
				// closeAfterTransition
				BackdropComponent={Backdrop}
				// BackdropProps={{
				//   timeout: 1,
				// }}
			>
				<Fade in={addModal}>
					<form onSubmit={addStockAction}>
						<Box
							m={3}
							display='flex'
							flexDirection='column'
							alignItems='flex-end'
							className={classes.paper}
							px={4}
							py={3}>
							<Typography variant='h1'>Add New Stock</Typography>
							<Box mt={2} width='100%'>
								<TextField
									onChange={(e) => {
										validateTicker(e.target.value);
									}}
									// error={!validTicker}
									variant='outlined'
									label='Stock Symbol'
									inputProps={{
										style: { textTransform: "uppercase" },
									}}
									autoFocus
									fullWidth>
									Hey!
								</TextField>
							</Box>
							<Box mt={3}>
								<Button
									variant='contained'
									color='secondary'
									disabled={!canAddStock}
									type='submit'>
									Add Stock
								</Button>
							</Box>
						</Box>
					</form>
				</Fade>
			</Modal>
		</Box>
	);
}
