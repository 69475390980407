import Button from "@material-ui/core/Button";
import { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { useSnackbar } from "notistack";
import { alpacaKeysAtom, currentAccountAtom } from "../../atoms";
import {
	Box,
	Typography,
    TextField
} from "@material-ui/core";
import { AlpacaExecuter } from "../../../actions/actions";

export default function KevQuicks() {
	const currentAccount = useRecoilValue(currentAccountAtom);

	// Notification Handler
	const { enqueueSnackbar } = useSnackbar();
	const snack = (message, type, persist) => {
		enqueueSnackbar(message, {
			variant: type,
			autoHideDuration: 3000,
			persist: persist,
		});
	};

	// Page State Management (inputs and results)
	const [symbol, setSymbol] = useState("");

	const [buyPrice, setBuyPrice] = useState("");
	const [buyAmount, setBuyAmount] = useState("");
	const [buySegments, setBuySegments] = useState("");
	const [buyTargetPercent, setBuyTargetPercent] = useState("");

	const [sellSegments, setSellSegments] = useState("");
	const [sellTargetPercent, setSellTargetPercent] = useState("");
	const [sellPrice, setSellPrice] = useState("");
	const [sellQuantity, setSellQuantity] = useState("");

	// Page Functions
	const CacheRelayer = async (message) => {
		let messageBody = {};
		let cacheExec = {};
		switch (message) {
			case "cancelAllSymbol":
				messageBody = { k: currentAccount.key, d: {c: message, s: symbol} };

				cacheExec = AlpacaExecuter("alpacaCommand", messageBody, currentAccount.key);
				break;
			case "sonarBuy":
				messageBody = { k: currentAccount.key, d: {c: message, s: symbol, p: parseFloat(buyPrice), g: parseFloat(buyAmount), n: parseFloat(buySegments), a: parseFloat(buyTargetPercent)} };
                
				cacheExec = AlpacaExecuter(message, messageBody, currentAccount.key);
				break;
            case "sonarSell":
                messageBody = { k: currentAccount.key, d: {c: message, s: symbol, p: parseFloat(sellPrice), q: parseFloat(sellQuantity), n: parseFloat(sellSegments), a: parseFloat(sellTargetPercent)} };
                
                cacheExec = AlpacaExecuter(message, messageBody, currentAccount.key);
                break;
			default:
				console.log(message);
		}
	};
	//

	return (
		<Box className='mainContainer'>
			<Box display='flex' alignItems='center' mb={4}>
				<Typography variant='h1'>Kev Quicks</Typography>
			</Box>
			<Box display='flex' flexDirection='column' width={1} mb={4}
                    sx={{
                        border: "2px solid gray",
                        borderRadius: "4px",
                    }}>
                <Box display='flex' alignItems='center' mb={6} mt={2} ml={2}>
                    <Typography variant='h1'>Sonar</Typography>
                </Box>
                <Box display='flex' mb={2} ml={2}>
					<Box display='flex'>
						<Typography variant='h2'>Symbol</Typography>
					</Box>
					<Box width='auto' ml={2} mr={2}>
						<TextField
							variant='outlined'
							width={80}
							sx={{ borderRadius: 6 }}
							placeholder='AAPL'
							id='symbolId'
							name='symbol'
							value={symbol}
							onChange={(e) => setSymbol(e.target.value)}
						/>
					</Box>
                    <Button
                        fontWeight='bold'
                        onClick={() => CacheRelayer("cancelAllSymbol")}
                        color='secondary'
                        variant='contained'>
                        Cancel Symbol
                    </Button>
				</Box>
				<Box display='flex' width={1}
					sx={{
						border: "2px solid gray",
						borderRadius: "4px",
					}}>
					<Box display='flex' flexDirection='column' width={.5} bgcolor={"#FFBBBB"}
						sx={{
							border: "2px solid gray",
							borderRadius: "4px",
						}}>
						<Box display='flex' mt={2} mb={4} ml={2}>
							<Button
								fontWeight='bold'
								onClick={() => CacheRelayer("sonarBuy")}
								color='secondary'
								variant='contained'>
								Buy Sonar
							</Button>
						</Box>
						<Box display='flex' mb={3} ml={2}>
							<Box display='flex'>
								<Typography variant='h4'>Price</Typography>
							</Box>
							<Box width='auto' ml={2} bgcolor={"#FFEEEE"}>
								<TextField
									variant='outlined'
									width={80}
									sx={{ borderRadius: 6 }}
									placeholder='0.00'
									id='buyPriceid'
									name='buyPrice'
									value={buyPrice}
									onChange={(e) => setBuyPrice(e.target.value)}
								/>
							</Box>
						</Box>
						<Box display='flex' mb={3} ml={2}>
							<Box display='flex'>
								<Typography variant='h4'>Amount</Typography>
							</Box>
							<Box width='auto' ml={2} bgcolor={"#FFEEEE"}>
								<TextField
									variant='outlined'
									width={80}
									sx={{ borderRadius: 6 }}
									placeholder='35000'
									id='buyAmountId'
									name='buyAmount'
									value={buyAmount}
									onChange={(e) => setBuyAmount(e.target.value)}
								/>
							</Box>
						</Box>
						<Box display='flex' mb={3} ml={2}>
							<Box display='flex'>
								<Typography variant='h4'>Segments</Typography>
							</Box>
							<Box width='auto' ml={2} bgcolor={"#FFEEEE"}>
								<TextField
									variant='outlined'
									width={80}
									sx={{ borderRadius: 6 }}
									placeholder='30'
									id='buySegmentsId'
									name='buySegments'
									value={buySegments}
									onChange={(e) => setBuySegments(e.target.value)}
								/>
							</Box>
						</Box>
						<Box display='flex' mb={3} ml={2}>
							<Box display='flex'>
								<Typography variant='h4'>Target %</Typography>
							</Box>
							<Box width='auto' ml={2} bgcolor={"#FFEEEE"}>
								<TextField
									variant='outlined'
									width={80}
									sx={{ borderRadius: 6 }}
									placeholder='0.05'
									id='buyTargetPercentId'
									name='buyTargetPercent'
									value={buyTargetPercent}
									onChange={(e) => setBuyTargetPercent(e.target.value)}
								/>
							</Box>
						</Box>
					</Box>
					<Box display='flex' flexDirection='column' width={.5} bgcolor={"#BBFFBB"}
						sx={{
							border: "2px solid gray",
							borderRadius: "4px",
						}}>
						<Box display='flex' mt={2} mb={4} ml={2}>
							<Button
								fontWeight='bold'
								onClick={() => CacheRelayer("sonarSell")}
								color='secondary'
								variant='contained'>
								Sell Sonar
							</Button>
						</Box>
						<Box display='flex' mb={3} ml={2}>
							<Box display='flex'>
								<Typography variant='h4'>Segments</Typography>
							</Box>
							<Box width='auto' ml={2} bgcolor={"#EEFFEE"}>
								<TextField
									variant='outlined'
									width={80}
									sx={{ borderRadius: 6 }}
									placeholder='25'
									id='sellSegmentsId'
									name='sellSegments'
									value={sellSegments}
									onChange={(e) => setSellSegments(e.target.value)}
								/>
							</Box>
						</Box>
						<Box display='flex' mb={3} ml={2}>
							<Box display='flex'>
								<Typography variant='h4'>Target %</Typography>
							</Box>
							<Box width='auto' ml={2} bgcolor={"#EEFFEE"}>
								<TextField
									variant='outlined'
									width={80}
									sx={{ borderRadius: 6 }}
									placeholder='0.005'
									id='sellTargetPercentId'
									name='sellTargetPercent'
									value={sellTargetPercent}
									onChange={(e) => setSellTargetPercent(e.target.value)}
								/>
							</Box>
						</Box>
						<Box display='flex' mb={3} mt={4} ml={2}>
							<Box display='flex'>
								<Typography variant='h3'>Overrideables</Typography>
							</Box>
						</Box>
						<Box display='flex' mb={3} ml={2}>
							<Box display='flex'>
								<Typography variant='h4'>Target Price</Typography>
							</Box>
							<Box width='auto' ml={2} bgcolor={"#FFEEEE"}>
								<TextField
									variant='outlined'
									width={80}
									sx={{ borderRadius: 6 }}
									placeholder='0.00'
									id='sellPriceId'
									name='sellPrice'
									value={sellPrice}
									onChange={(e) => setSellPrice(e.target.value)}
								/>
							</Box>
						</Box>
						<Box display='flex' mb={3} ml={2}>
							<Box display='flex'>
								<Typography variant='h4'>Quantity</Typography>
							</Box>
							<Box width='auto' ml={2} bgcolor={"#FFEEEE"}>
								<TextField
									variant='outlined'
									width={80}
									sx={{ borderRadius: 6 }}
									placeholder='0'
									id='sellQuantityId'
									name='sellQuantity'
									value={sellQuantity}
									onChange={(e) => setSellQuantity(e.target.value)}
								/>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box width={1} flexDirection='row' mt={4} mb={2}>
					<Box display='flex' ml={2}>
						<Typography variant='h2'>Notes</Typography>
					</Box>
					<Box width='auto' ml={2}>
						<Typography variant='h6'>
							- Trade half of what you can lose. Trade 3 increments of this? (referenced half loss, full loss below)<br/><br/>
							- <b>6:30 to 9:00</b> Find a Stock with highest volume. Put 3 pulses of <b>half max loss</b> each.<br/>
							- 1. where the stock should bounce from<br/>
							- 2. where the previous support is<br/>
							- 3. next major support below that<br/>
							- <b>9:00 to 11:30</b> Find a Stock with highest volume. Put 3 pulses of <b>full loss</b> each. (double from above)<br/>
							- 1. where the stock should bounce from<br/>
							- 2. where the previous support is<br/>
							- 3. next major support below that<br/>
							- <b>11:30 to 13:00</b> Does it make sense to continue?<br/><br/>
							- Are there good multi-day runners?<br/>
							- What's the news on the change?<br/>
							- Which direction is the stock heading? (longer channel up is very safe)<br/><br/>
							- Stocks falling max average 20%<br/>
							- After the bounce, it can be around 5% lower than where it started<br/><br/>
							- Avoid anything looking like a barCode<br/>
							- Consistent wavy stocks are safest<br/>
							- Note the stocks with long candle wicks: best opportunities to average down or sell off<br/>
							- Check your volume: 1. May scare away buyers; 2. May be unable to sell<br/>
							- Jump stocks if you think it's safer<br/>
							- Keep an eye for downtrends (these are scary)<br/>
							- Cautious after 9:00 - 10:00 and after 11:00<br/>
							- Be quick to pull the sells (important to make it easy to break even)<br/>
							- Keep the holds to about 1K - 2K and use the rest of the allocation to break even<br/>
							- Target a max of 10K (Over this is way way scary)<br/>
							- Phone Text the symbol you will be trading<br/>
							- Do a light test before trading to make sure things are functional<br/>
							- Don't refresh Guillotine too many times<br/>
							- Buy segments should stay around 45 and 0.25 (percent as decimal)<br/>
							- Sell segments should be between 20 with 0.01 to 0.05 (percent as decimal)<br/>
							- Do you have any indicators you use? e.g. RSI, MFI, CMF, Oscillators (I'm using in conjunction with iASA 2.1.x)<br/>
							- Call if you're in a bind<br/>
							- Alpaca uses FIFO meaning averages don’t work properly (selling brings the average down)
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
