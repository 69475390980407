export default function Nada() {
  return "";
}

export function GetWebhookUrlFromConfig(configKey, isReport) {
  const protoDomain_Kevin = "https://kevin.autoscalper.trade";
  const protoDomain_Spencer = "https://spencer.autoscalper.trade";

  if (configKey === "OffWithItsScalp") return `${protoDomain_Kevin}/MasterSim/`;
  if (configKey === "OffWithItsScalp" && isReport)
    return `${protoDomain_Kevin}/RepSim/`;
    
  if (configKey === "OffWithItsHead") return `${protoDomain_Spencer}/MasterAccount/`;
  if (configKey === "OffWithItsHead" && isReport)
    return `${protoDomain_Spencer}/RepMaster/`;
    
  return "";
}
