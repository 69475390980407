import Button from "@material-ui/core/Button";
import { Textarea } from "theme-ui";
import { useState, useEffect } from "react";
import { useRecoilValue, useRecoilState } from "recoil";
import { CacheExecuter } from "../../../actions/actions";
import { useSnackbar } from "notistack";
import { alpacaKeysAtom, currentAccountAtom } from "../../atoms";
import {
	Box,
	FormControl,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
	OutlinedInput,
	InputLabel,
	Typography,
	Divider,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { AlpacaExecuter, getKevinsExecutions } from "../../../actions/actions";

export default function Settings() {
	// Alpaca Key States / Handlers
	const currentAccount = useRecoilValue(currentAccountAtom);
	// const [alpacaKeys, setAlpacaKeys] = useRecoilState(alpacaKeysAtom);
	// const [alpacaKeysTemp, setAlpacaKeysTemp] = useState({
	// 	key: alpacaKeys.key,
	// 	secret: alpacaKeys.secret,
	// 	showSecret: alpacaKeys.showSecret,
	// });

	// // Alpca Key / Secret Handler
	// const changeAlpacaKeysTemp = (update, value) => {
	// 	var alpacaKeysTempCopy = { ...alpacaKeysTemp };
	// 	if (update === "key") {
	// 		alpacaKeysTempCopy.key = value;
	// 	} else {
	// 		alpacaKeysTempCopy.secret = value;
	// 	}
	// 	setAlpacaKeysTemp(alpacaKeysTempCopy);
	// };
	// const updateLiveAlpacaKeys = (e) => {
	// 	e.preventDefault();
	// 	setAlpacaKeys(alpacaKeysTemp);
	// 	snack("Alpaca Keys Successfully Updated!", "success");
	// };

	// // Alpaca Secret Hider
	// const handleClickShowPassword = () => {
	// 	setAlpacaKeysTemp({
	// 		...alpacaKeysTemp,
	// 		showSecret: !alpacaKeysTemp.showSecret,
	// 	});
	// };
	// const handleMouseDownPassword = (event) => {
	// 	event.preventDefault();
	// };

	// 200ms Get All Cache For All Your Execution Needs
	useEffect(() => {
		CacheRelayer("getAllKeyValues");
		const initInterval = setInterval(() => {
			CacheRelayer("getAllKeyValues");
		}, 2000);
		return () => clearInterval(initInterval);
	}, [currentAccount]);

	// Kevin's Guillotine Executer Lol
	const [kevinsExecutions, setKevinsExecutions] = useState([
		{
			action: "placeHolder",
			name: "Loading Executions",
		},
	]);

	useEffect(() => {
		getKevinsExecutions(currentAccount).then((d) => {
			setKevinsExecutions(d);
		});
	}, []);

	const AlpacaRelayer = (message) => {
		AlpacaExecuter(
			message,
			{
				k: currentAccount.key,
				d: {
					c: message,
				},
			},
			currentAccount.key,
			false
		);
		snack("Successfully Excecuted: " + message, "success");
	};

	// Notification Handler
	const { enqueueSnackbar } = useSnackbar();
	const snack = (message, type, persist) => {
		enqueueSnackbar(message, {
			variant: type,
			autoHideDuration: 3000,
			persist: persist,
		});
	};

	// Page State Management (inputs and results)
	const [allCacheResult, setAllCacheResult] = useState(
		"{Cache Config not Loaded}"
	);
	const [cacheInput_getKey, setCacheInput_getKey] = useState("");
	const [cacheResult_getKey, setCacheResult_getKey] =
		useState("{Cache not Loaded}");
	const [cacheInput_updateKey, setCacheInput_updateKey] = useState("");
	const [cacheInput_updateValue, setCacheInput_updateValue] = useState("");
	const [cacheInput_deleteKey, setCacheInput_deleteKey] = useState("");

	// Page Functions
	const CacheRelayer = async (message) => {
		let messageBody = {};
		let cacheExec = {};
		switch (message) {
			case "getAllKeyValues":
				messageBody = { k: currentAccount.key };

				cacheExec = CacheExecuter(message, messageBody, currentAccount.key);
				cacheExec.then((data) => {
					setAllCacheResult(JSON.stringify(data, undefined, 4));
				});
				break;
			case "getKeyValue":
				messageBody = { k: currentAccount.key, y: cacheInput_getKey };

				cacheExec = CacheExecuter(message, messageBody, currentAccount.key);
				cacheExec.then((data) => {
					setCacheResult_getKey(data);
				});
				break;
			case "updateKeyValue":
				messageBody = {
					k: currentAccount.key,
					y: cacheInput_updateKey,
					v: cacheInput_updateValue,
				};

				CacheExecuter(message, messageBody, currentAccount.key);
				break;
			case "deleteKeyValue":
				messageBody = { k: currentAccount.key, y: cacheInput_deleteKey };

				CacheExecuter(message, messageBody, currentAccount.key);
				break;
			case "clearAllKeyValues":
				messageBody = { k: currentAccount.key };

				CacheExecuter(message, messageBody, currentAccount.key);
				break;
			default:
				console.log(message);
		}
	};
	//

	return (
		<Box maxWidth={725} className='mainContainer'>
			<Box display='flex' alignItems='center' mb={4}>
				<Typography variant='h1'>Settings</Typography>
			</Box>
			{/* <Box mb={4}>
				<form onSubmit={updateLiveAlpacaKeys}>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={4}>
							<TextField
								variant='outlined'
								label='Alpaca Key'
								name='key'
								value={alpacaKeysTemp.key}
								onChange={(e) =>
									changeAlpacaKeysTemp(e.target.name, e.target.value)
								}
								fullWidth
							/>
						</Grid>
						<Grid item xs={12} sm={8}>
							<FormControl fullWidth variant='outlined'>
								<InputLabel>Alpaca Secret</InputLabel>
								<OutlinedInput
									type={
										alpacaKeysTemp.showSecret ? "text" : "password"
									}
									value={alpacaKeysTemp.secret}
									name='secret'
									onChange={(e) =>
										changeAlpacaKeysTemp(
											e.target.name,
											e.target.value
										)
									}
									labelWidth={105}
									endAdornment={
										<InputAdornment position='end'>
											<IconButton
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}>
												{alpacaKeysTemp.showSecret ? (
													<Visibility />
												) : (
													<VisibilityOff />
												)}
											</IconButton>
										</InputAdornment>
									}
								/>
							</FormControl>
						</Grid>
					</Grid>
					<Box display='flex' justifyContent='flex-end' mt={3}>
						<Button variant='contained' color='secondary' type='submit'>
							update
						</Button>
					</Box>
				</form>
			</Box> */}

			<Box mb={4}>
				<Box mb={3}>
					<Typography variant='h6'>
						Kevin's Guillotine Execution Center
					</Typography>
				</Box>
				{kevinsExecutions.map((v) => (
					<Box key={v.name} display='inline-block' mr={3} mb={3}>
						<Button
							variant='contained'
							color='secondary'
							onClick={() => AlpacaRelayer(v.action)}>
							{v.name}
						</Button>
					</Box>
				))}
				<Box mt={1} mb={3}>
					<Divider />
				</Box>
			</Box>

			<Box display='flex' flexDirection='column' width={1}>
				<Box display='flex' mb={4}>
					<Box
						display='flex'
						flexDirection='column'
						p={4}
						sx={{
							border: "2px solid gray",
							borderRadius: "4px",
						}}>
						<Typography variant='h2'>All Cache</Typography>
						<Box width='auto'>
							{/* <Button
								fontWeight='bold'
								onClick={() => CacheRelayer("getAllKeyValues")}
								color='secondary'
								variant='contained'>
								Get All Cache
							</Button> */}
							<Textarea
								mt={3}
								id='allCacheId'
								name='allCache'
								sx={{ borderRadius: "5px" }}
								cols='120'
								rows='20'
								readOnly
								value={allCacheResult}
							/>
						</Box>
					</Box>
				</Box>
				<Box display='flex' mb={4}>
					<Box
						display='flex'
						p={4}
						sx={{
							border: "2px solid gray",
							borderRadius: "4px",
						}}
						flexDirection='column'>
						<Box display='flex' pb={1}>
							<Typography variant='h2'>
								Get Cache Value from Key
							</Typography>
						</Box>
						<Box display='flex' alignItems='flex-end'>
							<Box display='flex' pr={4} flexDirection='column'>
								<Box>
									<Typography variant='h6'>Cache Key</Typography>
								</Box>
								<Box mt={1}>
									<TextField
										variant='outlined'
										width={180}
										sx={{ borderRadius: 6 }}
										mt={1}
										mb={1}
										value={cacheInput_getKey}
										onChange={(e) =>
											setCacheInput_getKey(e.target.value)
										}
									/>
								</Box>
							</Box>
							<Box mb={1} display='flex' pr={4} flexDirection='column'>
								<Button
									fontWeight='bold'
									mt={"auto"}
									onClick={() => CacheRelayer("getKeyValue")}
									color='secondary'
									variant='contained'>
									Get Cache Value
								</Button>
							</Box>
							<Box display='flex' pr={4} flexDirection='column'>
								<Box>
									<Typography variant='h6'>Cache Value</Typography>
								</Box>
								<Box mt={1}>
									<TextField
										variant='outlined'
										width={180}
										sx={{ borderRadius: 6 }}
										value={cacheResult_getKey}
										mt={1}
										mb={1}
										readOnly
									/>
								</Box>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box display='flex' mb={4}>
					<Box
						display='flex'
						p={4}
						sx={{
							border: "2px solid gray",
							borderRadius: "4px",
						}}
						flexDirection='column'>
						<Box display='flex' pb={1}>
							<Typography variant='h2'>Update Cache</Typography>
						</Box>
						<Box display='flex' alignItems='flex-end'>
							<Box display='flex' pr={4} flexDirection='column'>
								<Box>
									<Typography variant='h6'>Cache Key</Typography>
								</Box>
								<Box mt={1}>
									<TextField
										variant='outlined'
										width={180}
										sx={{ borderRadius: 6 }}
										placeholder='Key'
										mt={1}
										mb={1}
										value={cacheInput_updateKey}
										onChange={(e) =>
											setCacheInput_updateKey(e.target.value)
										}
									/>
								</Box>
							</Box>
							<Box display='flex' pr={4} flexDirection='column'>
								<Box>
									<Typography variant='h6'>Cache Value</Typography>
								</Box>
								<Box mt={1}>
									<TextField
										variant='outlined'
										width={180}
										sx={{ borderRadius: 6 }}
										placeholder='Value'
										mt={1}
										mb={1}
										value={cacheInput_updateValue}
										onChange={(e) =>
											setCacheInput_updateValue(e.target.value)
										}
									/>
								</Box>
							</Box>
							<Box mb={1} display='flex' pr={4} flexDirection='column'>
								<Button
									fontWeight='bold'
									mt={"auto"}
									onClick={() => CacheRelayer("updateKeyValue")}
									color='secondary'
									variant='contained'>
									Update Cache
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box display='flex' mb={4}>
					<Box
						display='flex'
						p={4}
						sx={{
							border: "2px solid gray",
							borderRadius: "4px",
						}}
						flexDirection='column'>
						<Box display='flex' pb={1}>
							<Typography variant='h2'>Delete Cache Key</Typography>
						</Box>
						<Box display='flex' alignItems='flex-end'>
							<Box display='flex' pr={4} flexDirection='column'>
								<Box>
									<Typography variant='h6'>Cache Key</Typography>
								</Box>
								<Box mt={1}>
									<TextField
										variant='outlined'
										width={180}
										sx={{ borderRadius: 6 }}
										placeholder='Key'
										mt={1}
										mb={1}
										value={cacheInput_deleteKey}
										onChange={(e) =>
											setCacheInput_deleteKey(e.target.value)
										}
									/>
								</Box>
							</Box>
							<Box mb={1} display='flex' pr={4} flexDirection='column'>
								<Button
									fontWeight='bold'
									mt={"auto"}
									onClick={() => CacheRelayer("deleteKeyValue")}
									color='secondary'
									variant='contained'>
									Delete Cache Key
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
				<Box display='flex' mb={4}>
					<Box
						display='flex'
						p={4}
						sx={{
							border: "2px solid gray",
							borderRadius: "4px",
						}}
						flexDirection='column'>
						<Box display='flex' pb={1}>
							<Typography variant='h2'>Delete All Cache</Typography>
						</Box>
						<Box display='flex' alignItems='flex-end'>
							<Box mb={1} display='flex' pr={4} flexDirection='column'>
								<Button
									fontWeight='bold'
									mt={"auto"}
									onClick={() => CacheRelayer("clearAllKeyValues")}
									color='secondary'
									variant='contained'>
									Delete All Cache
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	);
}
